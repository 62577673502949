@media screen and (max-width: 599px) {
  .rec-arrow-left,
  .rec-arrow-right {
    display: none;
  }
}

.rec-arrow-left,
.rec-arrow-right {
  background-color: rgba(67, 133, 197, 0.1) !important;
}

.rec-arrow-left:hover,
.rec-arrow-right:hover {
  background-color: rgba(67, 133, 197, 0.685) !important;
}

.rec-arrow-left:hover:enabled,
.rec-arrow-right:hover:enabled {
  background-color: rgba(67, 133, 197, 0.932) !important;
}

.rec-dot:hover {
  box-shadow: 0 0 1px 3px rgb(67, 133, 197) !important;
}

.rec-dot_active {
  box-shadow: 0 0 1px 3px rgb(67, 133, 197) !important;
  background-color: rgba(67, 133, 197, 0.493) !important;
}
